import { ElUpload } from 'element-plus';
import token from '@/libs/token';

const wrap = function (target, proxy) {
  return function (__, ___) {
    return proxy(target, ...arguments);
  };
};

ElUpload.tokenRefresh = token.refresh;

/* Upload */
ElUpload.props.virtualTrigger = {
  type: Boolean,
  default: false
}; // 虚拟触发
ElUpload.setup = wrap(ElUpload.setup, function (setup, props, context) {
  let $class = '';
  if (props.virtualTrigger) {
    $class = `el-upload--virtual-trigger`;
  }

  function trigger() {
    this.$el.querySelector('input').click();
  }

  const render = setup(props, context);
  return function (ctx, ...args) {
    const vnode = render.call(this, ctx, ...args);
    if (vnode.props) {
      vnode.props = Object.assign({ class: `${$class} ${vnode.props.class}` }, { ...vnode.props });
    } else {
      vnode.props = { class: $class };
    }
    if (props.virtualTrigger) {
      vnode.ctx.exposed = Object.assign({ trigger }, { ...vnode.ctx.exposed });
    }
    return vnode;
  };
});
