import { defineComponent, ref } from 'vue';
import { ElMessage as $msg } from 'element-plus';
export default defineComponent({
  name: 'EeFileDownloadButton',
  props: {
    href: {
      type: String,
      required: true
    },
    data: {
      type: Object,
      default: {}
    }
  },
  emits: ['beforeDownload'],
  setup(props, {
    emit,
    slots
  }) {
    const loading = ref(false);
    return {
      loading,
      circle: slots.default ? false : true,
      handleDownload() {
        loading.value = true;
        let download = true;
        emit('beforeDownload', $download => download = $download);
        if (!download) {
          loading.value = false;
          return;
        }
        let params = '?1=1';
        Object.entries(props.data).forEach(element => {
          const value = element[1];
          if (value != undefined && value != 'undefined') {
            params = params + '&' + element[0] + '=' + value;
          }
        });
        // 暂时延迟5秒关闭加载
        setTimeout(() => {
          loading.value = false;
        }, 5000);
        let iframe = document.createElement('iframe');
        //const url = encodeURIComponent(props.href + params);
        iframe.src = props.href + params;
        iframe.onload = () => {
          $msg.error('文件下载失败或暂无数据！');
          document.body.removeChild(iframe);
        };
        iframe.style.display = 'none';
        document.body.appendChild(iframe);

        /* var iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
        const timer = setInterval(function () {
          if (iframeDoc.readyState == 'complete' || iframeDoc.readyState == 'interactive') {
            const message = iframeDoc.body.innerHTML;
             message && $msg.error('文件下载失败！');
            document.body.removeChild(iframe);
            clearInterval(timer);
            return;
          }
        }, 1000); */
      }
    };
  }
});