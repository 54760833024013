import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "website/home" */ './views/home.vue')
  },
  {
    path: '/lines',
    name: 'lines',
    component: () => import(/* webpackChunkName: "website/lines" */ './views/lines.vue')
  },
  {
    path: '/lines_fee',
    name: 'lines-fee',
    component: () => import(/* webpackChunkName: "website/lines-fee" */ './views/lines-fee.vue')
  },
  {
    path: '/news',
    name: 'news',
    component: () => import(/* webpackChunkName: "website/news" */ './views/news/news.vue'),
    children: [
      {
        path: '',
        name: 'news-list',
        component: () => import(/* webpackChunkName: "website/news" */ './views/news/news-list.vue')
      },
      {
        path: '/news/:id',
        name: 'news-detail',
        props: true,
        component: () => import(/* webpackChunkName: "website/news" */ './views/news/news-detail.vue')
      }
    ]
  },
  {
    path: '/notice',
    name: 'notice',
    component: () => import(/* webpackChunkName: "website/notice" */ './views/notice/notice.vue'),
    children: [
      {
        path: '',
        name: 'notice-list',
        component: () => import(/* webpackChunkName: "website/notice" */ './views/notice/notice-list.vue')
      },
      {
        path: '/notice/:id',
        name: 'notice-detail',
        props: true,
        component: () => import(/* webpackChunkName: "website/notice" */ './views/notice/notice-detail.vue')
      }
    ]
  },
  {
    path: '/process',
    name: 'process',
    component: () => import(/* webpackChunkName: "website/process" */ './views/process.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "website/about" */ './views/about.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/'
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(() => {
  document.getElementById('app').scrollTo({ top: 0 });
});

export default router;
